<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />

    <div class="apipe-cloud-course-container-case">
      <div class="top-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h2>客户案例</h2>
        </div>
        <MessageInfo />
      </div>
      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="">
          <h4>客户案例</h4>
        </div>
      </div>

      <div class="pc-case container d-none d-lg-block">
        <div class="catcary">
          <div class="content container" v-if="caseList.length">
            <div class="content-warp row row-cols-1 row-cols-lg-3 g-2 g-lg-3">
              <div
                class="itms col"
                v-for="(item, index) in caseList"
                :key="index"
              >
                <div class="cot p-3">
                  <div v-if="item.tag != 'more'">
                    <img
                      :src="baseImgUrl + item.contentImgUrl"
                      alt=""
                      style="width: 100%"
                    />
                    <div class="tit" :title="item.contentTitle">{{ item.contentTitle }}</div>
                    <div class="des" :title="item.contentDescription">{{ item.contentDescription }}</div>
                    <el-button
                      @click="handleDetail(`/caseDetail?id=${item.id}&tit=${item.contentTitle}`)"
                      type="primary"
                      round
                      size="mini"
                    >
                      查看详情</el-button
                    >
                  </div>
                  <div
                    v-else
                    style="
                      line-height: 370px;
                      text-align: center;
                      color: #333333;
                    "
                  >
                    {{ item.des }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pageNation container" v-if="caseList.length">
          <div class="preAndNext">
            <div
              :class="['before', current == 1 ? 'dis' : 'activ']"
              @click="lunboleft"
            >
              <span class="el-icon-arrow-left"></span>
            </div>
            <div class="nums">{{ current }} / {{ maxPage }}</div>
            <div
              :class="['after', current == maxPage ? 'dis' : 'activ']"
              @click="lunboright"
            >
              <span class="el-icon-arrow-right"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-case d-block d-lg-none">
        <div class="opt"></div>
        <div class="catcary">
          <div class="content">
            <div class="content-warp">
              <div class="itms" v-for="(item, index) in caseList" :key="index">
                <div class="cot" v-if="item.tag != 'more'">
                  <img :src="baseImgUrl + item.contentImgUrl" alt="" style="width: 100%" />
                  <div class="tit">{{ item.contentTitle }}</div>
                  <div class="des">{{ item.contentDescription }}</div>
                  <el-button
                      @click="handleDetail(`/caseDetail?id=${item.id}&tit=${item.contentTitle}`)"
                      type="primary"
                      round
                      size="mini"
                    >
                      查看详情</el-button
                    >
                </div>
                <div
                  v-else
                  style="line-height: 370px; text-align: center; color: #333333"
                >
                  <el-button type="primary" round>查看详情</el-button>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="pageNation container" v-if="caseList.length">
          <div class="preAndNext">
            <div
              :class="['before', current == 1 ? 'dis' : 'activ']"
              @click="lunboleft"
            >
              <span class="el-icon-arrow-left"></span>
            </div>
            <div class="nums">{{ current }} / {{ maxPage }}</div>
            <div
              :class="['after', current == maxPage ? 'dis' : 'activ']"
              @click="lunboright"
            >
              <span class="el-icon-arrow-right"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NewFooter @handleClickChild="handleClickChild"/>
    <MobileFooter ref="mofooter" class="d-block d-lg-none" />
  </div>
</template>
      
<script>
import { Newslist } from "@/api/fetch";
import Qs from "qs";
export default {
  name: "hardware",
  data() {
    return {
      cur: 1,
      activeName: "1",
      idx: 0,
      ids: -1,

      total: 0,
      current: 1,
      maxPage: 1,
      pages: {
        pageSize: "10",
        pageNo: 1,
      },
      seach: {
        indus: "全部",
        pro: "全部",
      },
      sel: "",
      showIndus: false,
      showPro: false,
      baseImgUrl: "https://admin.veiban.com",
      caseList: [],
    };
  },
  created() {
    this.getCase();
  },
  methods: {
    handleClickChild(){
     this.$refs.mofooter.handleDeal(2)
    },
    //成功案例
    getCase() {
      Newslist(
        Qs.stringify({
          categoryId: "1839482945283117058",
          pageSize: 10,
          pageNo: 1,
          productCode: "all",
        })
      )
        .then((res) => {
          if (res.data.rows) {
            this.total = res.data.total;
            this.caseList = res.data.rows || [];
            this.maxPage = Math.ceil(this.total / this.pages.pageSize);

            // this.caseList =  res.data.rows
          }
          // console.log(res, 999);
        })
        .catch((e) => {
          this.listcot = [];
          this.total = 0;
          this.maxPage = 0;
        });
    },
    handleDetail(path){
     this.openNewWindow(path)
    },
    openNewWindow(path) {
      const routeData = this.$router.resolve({ path });
      window.open(routeData.href, "_blank");
    },
    lunboleft() {
      if (this.current == 1) return;
      this.current = this.current - 1;
      this.pages.pageNo = this.current;
      this.getCase();
    },
    lunboright() {
      if (this.current == this.maxPage) return;
      this.current = this.current + 1;
      this.pages.pageNo = this.current;
      this.getCase();
    },

    handleCase() {
      const prams = this.pages;
      Newslist(Qs.stringify(prams)).then((res) => {
        this.total = res.data.total;
        // this.listcot = res.data.rows;
        this.maxPage = Math.ceil(this.total / this.pages.pageSize);
      });
    },
    handlePre(e) {
      console.log(e);
      this.pages.pageNo = e;
      this.handleCase();
    },
    handleNext(e) {
      console.log(e);
      this.pages.pageNo = e;
      this.handleCase();
    },
  },
};
</script>
      
<style lang="less"  scoped>
.aa:active {
  background-color: #ff0000; /* 点击时背景变为红色 */
  color: #ffffff; /* 文字变为白色 */
}

.apipe-cloud-course-container-case {
  padding-bottom: 10px;
  background: #f5f6f7;
  .top-banner {
    height: 600px;
    background-image: url("../../assets/newSite/case/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 16px;
      }
    }
  }
  .mobile-banner {
    height: 200px;
    background-image: url("../../assets/newSite/home/p3.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 12px;
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .pc-case {
    margin-top: 30px;
    .pro-tit {
      text-align: left;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 10px;
    }
    .catcary {
      text-align: left;
      margin-top: 30px;
      .catcary-wrap {
        display: flex;
        font-size: 14px;
        color: #666666;
        margin-bottom: 30px;
        .left {
          display: flex;
          margin: 5px 0;
          .indus {
            margin-right: 10px;
            width: 60px;
          }
          .all {
            width: 50px;
            cursor: pointer;
            height: fit-content;
            text-align: center;
          }
          .act {
            background-color: rgba(20, 132, 255, 10%);
            color: rgba(20, 132, 255, 1);
          }
        }
        .types {
          flex-flow: wrap;
          display: flex;
          .tgs {
            margin: 5px 10px;
            cursor: pointer;
            padding: 0 6px;
          }

          .act {
            background-color: rgba(20, 132, 255, 10%);
            color: rgba(20, 132, 255, 1);
          }
        }
      }
      .content {
        min-height: 600px;
        margin-bottom: 30px;
        .content-warp {
          .itms {
            // border-radius: 10px;
            img {
              width: 100%;
              height: 200px;
            }
            .cot {
              // border:1px solid red;
              background: white;
              min-height: 400px;
              border-radius: 10px;
              .des {
                font-size: 14px;
                color: #666666;
                margin: 6px 0 20px 0;
                height: 65px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
              }
              .tit {
                font-size: 16px;
                margin-top: 20px;
                color: #333333;
                font-weight: 700;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
            }
          }
        }
      }
    }

    .pageNation {
      padding: 10px 0;
      .preAndNext {
        display: flex;
        align-items: center;
        .nums {
          margin: 0 10px;
        }
        .before,
        .after {
          display: inline-block;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid #ccc;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }
        .dis {
          // border: 1px solid #ccc;
          cursor: not-allowed !important;
        }
        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
        }
      }
    }
  }
  .mobile-case {
    .pro-tit {
      text-align: left;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 10px;
    }
    .opt {
      display: flex;
      font-size: 14px;
      position: relative;
      .indus {
        flex: 1;
        // padding: 10px 0;
        .boader {
          position: absolute;
          height: 200px;
          width: 100%;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          left: 0;
          top: 30px;
          background: white;
          padding: 10px;
          display: flex;
          // justify-content: space-evenly;
          flex-flow: column;
          justify-content: space-evenly;
          .itms {
            // display: flex;
            // flex: 30%;
            padding: 10px;
          }
        }
      }
      .prod {
        flex: 1;
        // padding: 10px 0;

        .boader {
          position: absolute;
          height: 200px;
          width: 100%;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          left: 0;
          top: 30px;
          background: white;
          padding: 10px;
          display: flex;
          // justify-content: space-evenly;
          flex-flow: wrap;
          // justify-content: space-evenly;
          .itms {
            // display: flex;
            width: 30%;
          }
        }
      }
    }
    .catcary {
      text-align: left;
      margin-top: 10px;
      .catcary-wrap {
        display: flex;
        font-size: 14px;
        color: #666666;
        margin-bottom: 30px;
        .left {
          display: flex;
          margin: 5px 0;
          .indus {
            margin-right: 10px;
            width: 60px;
          }
          .all {
            width: 50px;
            cursor: pointer;
            height: fit-content;
            text-align: center;
          }
          .act {
            background-color: rgba(20, 132, 255, 10%);
            color: rgba(20, 132, 255, 1);
          }
        }
        .types {
          flex-flow: wrap;
          display: flex;
          .tgs {
            margin: 5px 10px;
            cursor: pointer;
            padding: 0 6px;
          }

          .act {
            background-color: rgba(20, 132, 255, 10%);
            color: rgba(20, 132, 255, 1);
          }
        }
      }
      .content {
        min-height: 600px;
        // border: 1px solid red;
        margin-bottom: 30px;
        .content-warp {
          display: flex;
          flex-flow: wrap;
          justify-content: space-between;
          .itms {
            width: 100%;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
            margin: 10px;
            padding: 10px;
            background: white;
            border-radius: 10px;
            img {
              width: 100%;
              height: 200px;
            }
            .cot {
              padding: 10px;
              border-radius: 10px;
              .des {
                font-size: 14px;
                color: #666666;
                margin: 6px 0;
              }
              .tit {
                font-size: 16px;
                color: #333333;
                margin: 10px 0;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    .pageNation {
      padding: 10px 0;
      .preAndNext {
        display: flex;
        align-items: center;
        justify-content: center;
        .nums {
          margin: 0 10px;
        }
        .before,
        .after {
          display: inline-block;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid #ccc;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }
        .dis {
          // border: 1px solid #ccc;
          cursor: not-allowed !important;
        }
        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
        }
      }
    }
  }
}
</style>
      